<template>
	<div class="row-space-tbf">
		<div class="space-left"></div>
		<div class="content">
			<v-app v-if="loaded">
				<section class="focus-section section-header">
					<EditableBlock :block="blockFocus" :project="project" @update-tag="block.tag = $event" @update-entire-block="handleDebounceFromChild" :key="`${blockFocus.id}-${componentsKey}`" />

					<div class="focus-actions">
						<!-- add responsible -->
						<div class="action-item">
							<div class="filter-dropdown-header avatars-dropdown" id="boxDropdownAvatars">
								<button id="dropdownAvatars" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<template v-if="responsible">
										<div class="icon"><img class="avatar" :src="responsible.avatar" /></div>
										<div class="text">{{ responsible.name }}</div>
									</template>
									<template v-else>
										<div class="icon"><icon-user /></div>
										<div class="text placeholder">{{ $t("general.responsible") }}</div>
									</template>
								</button>

								<div class="dropdown-menu" aria-labelledby="dropdownAvatars">
									<div class="search-filter-dropdown">
										<input type="text" v-model="searchUsersManagers" :placeholder="$t('add_task.choose_responsible')" />
									</div>

									<template v-for="user in filteredUsersManagers">
										<div class="dropdown-item" @click="selectResponsible(user)">
											<img class="avatar" :src="user.avatar" />
											<span class="name">{{ user.name }}</span>
										</div>
									</template>
								</div>
							</div>
						</div>
						<div class="action-item">
							<div class="filter-dropdown-header avatars-dropdown with-editors" id="boxDropdownAvatars">
								<button id="dropdownAvatars" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" class="popover-class">
										<template v-if="selectedUsers.length">
											<div class="users-avatars">
												<div class="image" v-for="user in selectedUsers.slice(0, 2)">
													<img :src="user.avatar" />
												</div>
												<div class="image plus-icon" v-if="selectedUsers.length > 2">
													<icon-plus />
													<span class="numer">{{ selectedUsers.length - 2 }}</span>
												</div>
											</div>
											<div class="text">{{ $t("projects.contributors") }}</div>
										</template>
										<template v-else>
											<div class="icon"><icon-people /></div>
											<div class="text placeholder">{{ $t("projects.contributors") }}</div>
										</template>

										<template slot="popover" v-if="selectedUsers.length">
											<div class="simple-text">
												{{
													selectedUsers
														.map((el) => {
															return el.name;
														})
														.join(", ")
												}}
											</div>
										</template>
										<template slot="popover" v-else>
											<div class="simple-text">{{ $t("projects.no_selected_colabs") }}</div>
										</template>
									</v-popover>
								</button>

								<div class="dropdown-menu" aria-labelledby="dropdownAvatars">
									<div class="search-filter-dropdown">
										<input type="text" v-model="searchUsers" :placeholder="$t('add_task.choose_responsible')" />
									</div>

									<template v-for="user in filteredUsers">
										<div class="dropdown-item" :class="{ selected: selectedUsers.find((el) => el.id == user.id) }" @click.stop="handleSelectUsers(user)">
											<img class="avatar" :src="user.avatar" />
											<span class="name">{{ user.name }}</span>
											<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
												<div
													class="editor"
													:class="{ active: selectedUsers.find((el) => el.id == user.id) ? selectedUsers.find((el) => el.id == user.id).editor : false }"
													@click.stop="setEditorRight(user)"
												>
													<icon-editors />
												</div>

												<template slot="popover">
													<div class="simple-text">{{ $t("projects.editor") }}</div>
												</template>
											</v-popover>
										</div>
									</template>
								</div>
							</div>
						</div>

						<div class="action-item progress-editor">
							<v-popover offset="10" trigger="hover" placement="top" popoverBaseClass="popover-tbf" :delay="{ show: 100, hide: 0 }">
								<input
									v-model="progressProject"
									type="range"
									min="0"
									max="100"
									class="slider-progress"
									:style="`background-size: ${progressProject}% 100%;`"
									@change="updateProgressProject"
								/>
								<div class="text-percent">{{ progressProject }}%</div>

								<template slot="popover">
									<div class="simple-text">{{ $t("projects.progress") }}</div>
								</template>
							</v-popover>
						</div>

						<section class="main-actions">
							<div class="last-update">
								{{ $t("general.last_update") }} <span class="date">{{ project.updated_at | moment("DD MMM YYYY, hh:mm") }}</span>
							</div>
						</section>
					</div>
				</section>

				<draggable tag="section" class="focus-section" v-model="blocksBody" handle=".handle-order" ghost-class="ghost" :key="`draggable-${componentsKey}`">
					<div class="item-handle-order" v-for="block in blocksBody" :key="`handle-${block.id}-${componentsKey}`">
						<EditableBlock
							:block="block"
							:project="project"
							@add-block="addBlock(block, $event)"
							@delete-block="deleteBlock($event, block)"
							@update-tag="block.tag = $event"
							@update-entire-block="handleDebounceFromChild"
							@checkNewUsersSelected="handleSelectUsers(false, $event)"
							@updateWebSocket="sendToWebsocket()"
							:key="`${block.id}-${componentsKey}`"
						/>
					</div>
				</draggable>
			</v-app>
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
import domMixin from "@/mixins/domMixin.js";
// Components
import EditableBlock from "@/components/Pages/Projects/Components/EditableBlock";

import IconUser from "@/components/Icons/User";
import IconPeople from "@/components/Icons/People";
import IconTags from "@/components/Icons/Tags";
import IconDate from "@/components/Icons/Date";
import IconEditors from "@/components/Icons/Editors";
import IconPlus from "@/components/Icons/Plus";

import { mapGetters } from "vuex";
import { debounce } from "debounce";
import draggable from "vuedraggable";

export default {
	mixins: [domMixin],
	components: {
		EditableBlock,
		IconUser,
		IconPeople,
		IconTags,
		IconDate,
		IconEditors,
		IconPlus,
		draggable,
	},
	data() {
		return {
			loaded: false,
			searchUsers: "",
			responsible: "",
			project: {},
			users: [],
			users_managers: [],
			selectedUsers: [],
			searchUsersManagers: "",
			componentsKey: 1,
			socket: "",
			progressProject: 0,
		};
	},
	watch: {
		"$route.params.slug": {
			handler: function(value, oldVal) {
				if (oldVal && value != oldVal && this.project.slug != value) {
					this.getFilters();
					this.getProjectData();
				}
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		...mapGetters({
			blockId: "blocks/blockId",
			blocksFilter: "blocks/blocksFilter",
		}),
		blocks: {
			get: function() {
				return this.$store.state.blocks.blocks;
			},
		},
		blockFocus() {
			return this.blockId(1);
		},
		blocksBody: {
			get: function() {
				return this.blocksFilter("body");
			},
			set: function(newValue) {
				var newOrderBlocks = [];
				newOrderBlocks.push({ ...this.blockFocus, order: 0 });

				newValue.map((el, index) => {
					newOrderBlocks.push({ ...el, order: index + 1 });
				});

				this.$store.dispatch("blocks/editEntireBlocks", newOrderBlocks);
			},
		},
		blocksTasks() {
			return this.blocksFilter("tasks");
		},
		filteredUsers() {
			var arraySelecteds = this.selectedUsers.map((el) => el.id);

			const sortedArr = this.users.reduce((acc, element) => {
				if (arraySelecteds.includes(element.id)) {
					return [element, ...acc];
				}
				return [...acc, element];
			}, []);

			return getByKeywordFilter(sortedArr, this.searchUsers);
		},
		filteredUsersManagers() {
			return getByKeywordFilter(this.users_managers, this.searchUsersManagers);
		},
	},
	created() {
		this.debouncedCallbackActions = debounce((withoutPush = false) => {
			this.updateProject(withoutPush);
		}, 1000);
	},
	beforeDestroy() {
		this.$root.$off("refreshProjectPage");
		this.socket.close();
	},
	async mounted() {
		await this.getFilters();
		await this.getProjectData();

		this.$root.$on("refreshProjectPage", () => {
			this.getProjectData();
		});
		this.initWebSocketProject();
	},
	methods: {
		initWebSocketProject() {
			this.socket = new WebSocket(process.env.VUE_APP_WSS_LINK_PROJECT);

			this.socket.onopen = (event) => {
				this.$cookies.set("websocket_project", "start");

				// check in to websocket
				this.socket.send(
					JSON.stringify({
						entity_id: this.project.id,
						user_id: this.$auth.user().id,
						type: "project",
					})
				);
			};

			// when receiving a message
			this.socket.onmessage = ({ data }) => {
				const parsedData = JSON.parse(data);

				this.$store.dispatch("blocks/editEntireBlocks", parsedData.message);

				// this.notifications.unshift(parsedData)
				// this.$root.$emit('updateNotificationsList', parsedData);

				// trebuie de aici facut push la notificare
			};

			this.socket.onerror = (error) => {
				console.log(`Websocket error`);
			};

			this.socket.onclose = (event) => {
				this.$cookies.set("websocket_project", "close");

				if (event.wasClean) {
					console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
				} else {
					// e.g. server process killed or network down
					// event.code is usually 1006 in this case
					console.log("[close] Connection died");

					setTimeout(() => {
						this.initWebSocketProject();
					}, 600000);
				}
			};
		},
		async getFilters() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, {
					params: {
						users: true,
						projects: true,
					},
				})
				.then(({ data }) => {
					this.users = Object.freeze(data.data.users);
					this.users_managers = Object.freeze(data.data.users.filter((el) => el.project_manager));
					this.$store.dispatch("blocks/setUsers", this.users);
					this.$store.dispatch("blocks/setProjects", Object.freeze(data.data.projects));
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				});
		},
		async getProjectData() {
			this.componentsKey++;
			this.loaded = false;

			await axios
				.get(`/projects/${this.$route.params.slug}/show`)
				.then(({ data }) => {
					this.$store.dispatch("blocks/editEntireBlocks", data.data.body);
					this.responsible = this.users.find((el) => el.id == data.data.responsible.id);
					this.selectedUsers = data.data.users;
					this.project = { slug: data.data.slug, id: data.data.id, updated_at: data.data.updated_at, pipeline_id: data.data.pipeline_id };
					this.progressProject = data.data.progress;

					var title = this.$t("projects.singular");
					this.$root.$emit("navbar_title", title);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 404) {
							this.$router.push({ name: "not-found" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true;
						setTimeout(() => {
							$(".opacity-page").addClass("show");
						}, 0);
					}, 0);
				});
		},
		async updateProject(withoutPush = false) {
			var formData = {};
			var formatedUsers = {};

			this.selectedUsers.map((el) => {
				formatedUsers[el.id] = { editor: el.editor };
			});

			formData.body = this.blocks;
			formData.responsible_id = this.responsible.id;
			formData.users = formatedUsers;
			formData.progress = this.progressProject;

			await axios.patch(`/projects/${this.project.slug}`, formData).then(({ data }) => {
				// this.$store.dispatch('blocks/editEntireBlocks', data.data.body);
				this.responsible = this.users.find((el) => el.id == data.data.responsible.id);
				this.selectedUsers = data.data.users;
				var needRefresh = this.project.slug != data.data.slug;
				this.project = { slug: data.data.slug, id: data.data.id, updated_at: data.data.updated_at };

				if (needRefresh) {
					this.$router.push({ name: "projects-edit", params: { slug: this.project.slug } });
				}
			});

			return true;
		},
		handleSelectUsers(user, userId = false) {
			if (this.responsible.id != user.id) {
				var findUserObj = this.users.find((el) => el.id == (userId ? userId : user.id));
				var userIndex = this.selectedUsers.findIndex((el) => el.id == (userId ? userId : user.id));

				if (userIndex >= 0) {
					if (!userId) {
						this.selectedUsers.splice(userIndex, 1);
					}
				} else {
					this.selectedUsers.push({ id: findUserObj.id, name: findUserObj.name, avatar: findUserObj.avatar, editor: 0 });
				}

				this.sendToWebsocket();
				this.debouncedCallbackActions();
			}
		},
		setEditorRight(user) {
			var userIndex = this.selectedUsers.findIndex((el) => el.id == user.id);

			if (userIndex >= 0) {
				this.selectedUsers[userIndex].editor = this.selectedUsers[userIndex].editor === 1 ? 0 : 1;
			} else {
				this.selectedUsers.push({ id: user.id, name: user.name, avatar: user.avatar, editor: 1 });
			}

			this.sendToWebsocket();
			this.debouncedCallbackActions();
		},
		selectResponsible(user) {
			this.responsible = user;

			if (!this.selectedUsers.find((el) => el.id == user.id)) {
				this.selectedUsers.push(user);
			}

			this.sendToWebsocket();
			this.updateProject();
			// this.debouncedCallbackActions();
		},
		getNewId() {
			const ids = this.blocks.map((el) => {
				return el.id;
			});
			return Math.max(...ids) + 1;
		},
		async addBlock(currentBlock, stopFocus = false) {
			// Current block is actually a DOM element
			let postionToPush = this.blocks.findIndex((el) => el.id == currentBlock.id) + 1;

			let nextElementType = "next";

			if (currentBlock.html && window.getSelection().anchorOffset == 0) {
				postionToPush--;
				nextElementType = "prev";
			}

			const newBlock = {
				id: this.getNewId(),
				section: currentBlock.section,
				html: "",
				tag: currentBlock.tag == "task" ? "task" : "p",
			};

			this.$store.dispatch("blocks/addBlock", { block: newBlock, postionToPush });

			this.$nextTick(() => {
				if (!stopFocus) {
					setTimeout(() => document.getElementById(`block-${newBlock.id}`).focus(), 0);
				}

				this.sendToWebsocket();
				this.debouncedCallbackActions();
			});
		},
		async deleteBlock(currentBlockEl, currentBlock) {
			currentBlockEl.preventDefault();

			const previousElement = this.getPreviousSibling(document.getElementById(`block-${currentBlock.id}`), ".block");

			if (previousElement) {
				this.$store.dispatch("blocks/deleteBlock", currentBlock);

				if (currentBlock.tag == "task" && currentBlock.task_id) {
					axios.post(`/tasks/${currentBlock.task_id}/delete`, { all: 1 });
				}

				this.$nextTick(() => {
					var previousId = previousElement.id;

					setTimeout(() => this.setCaretToEnd(document.getElementById(previousId)), 0);

					this.sendToWebsocket();
					this.debouncedCallbackActions();
				});
			}
		},
		setCaretToEnd(element) {
			const range = document.createRange();
			const selection = window.getSelection();
			range.selectNodeContents(element);
			range.collapse(false);
			selection.removeAllRanges();
			selection.addRange(range);
			element.focus();
		},
		async handleDebounceFromChild(instant = false) {
			this.sendToWebsocket();
			if (instant) {
				const finishCall = await this.updateProject(false);
				this.$router.push({ name: "projects-edit", params: { slug: instant } });
			} else {
				this.debouncedCallbackActions();
			}
		},
		sendToWebsocket() {
			// axios.post(`/projects/${this.project.slug}/sync-message`, { message: JSON.stringify(this.blocks) });
			this.socket.send(
				JSON.stringify({
					body: this.blocks,
					entity_id: this.project.id,
					entity_user_id: this.$auth.user().id,
					type: "project",
				})
			);
		},
		updateProgressProject() {
			this.debouncedCallbackActions();
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>
